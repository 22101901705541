<template>
  <div class="alert">
    <p class="message">🏗 You are seeing a deprecated version of my website, new site is under construction 🏗</p>
  </div>
</template>

<style scoped>
.alert {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: bisque;
  border-radius: 0;
}

.message {
  margin: 0;
  color: brown;
}
</style>
